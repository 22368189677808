
// SCRIPTS

//import { GeneralHomePage } from './home/home.page'; export { GeneralHomePage };
//import { AboutPage } from './about/about.page'; export { AboutPage };
//import { ResearchCenterPage } from './research-centers/research-center.page'; export { ResearchCenterPage };
//import { NetworkMapPage } from './network-map/scripts/network-map.page'; export { NetworkMapPage };

//import { SinglePage } from './single-page/scripts/single.page'; export { SinglePage }; //to make discoverable from cshtml
//import { ContactUsPage } from './contact-us/contact-us.page'; export { ContactUsPage };
//import { FundingPage } from './funding/funding.page'; export { FundingPage };
//import { NewsEventsPage } from './news-events/news-events.page'; export { NewsEventsPage };
////import { StudiesPage } from './studies/scripts/studies.page'; export { StudiesPage };
//import { AboutPage } from './about/about.page'; export { AboutPage };
//import { FaqsPage } from './faqs/faqs.page'; export { FaqsPage };
//import { ResearchPage } from './research/research.page'; export { ResearchPage };
//import { WhoWeArePage } from './who-we-are/who-we-are.page'; export { WhoWeArePage };
//import { ResearchWorksPage } from './research-works/research-works.page'; export { ResearchWorksPage };
//import { CommunityPage } from './community/community.page'; export { CommunityPage };
//import { LongCovidPage } from './long-covid/long-covid.page'; export { LongCovidPage };

// STYLES
import './home/home.scss';
import './about/about.scss';
import './faq/faq.scss';
import './book/book.scss';
import './team/team.scss';
import './contact/contact.scss';
//import './single-page/single-page.scss';
//import './single-page/coming-soon.scss';
//import './contact-us/contact-us.scss';
//import './funding/funding.scss';
//import './news-events/news-events.scss';
//import './about/about.scss';
//import './about/coordinating-center.scss';
//import './research-centers/research-centers.scss';
//import './network-map/network-map.scss';
//import './research/research.scss';
//import './who-we-are/who-we-are.scss';
//import './research-works/research-works.scss';
//import './community/community.scss';
//import './long-covid/long-covid.scss';

